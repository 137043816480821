<template>
  <main>
    <div class="form">
      <span class="big">Регистрация</span>
      <span>
        Хотите зарегистрироваться с помощью профиля в соцсети? Просто совершите
        <a href="#login" class="strong" @click="showLogInOverlay">вход</a>.
        <br><br>
      </span>
      <input type="email" placeholder="E-mail" autocomplete="username" v-model="email" />
      <input type="password" placeholder="Пароль" autocomplete="new-password" v-model="password" />
      <input type="password" placeholder="Повторите пароль" autocomplete="new-password" v-model="repeatPassword" />
      <input type="text" placeholder="Отображаемое имя" v-model="username" />
      <span>
        Регистрируясь на сайте, вы соглашаетесь с
        <a href="/p/terms" target="_blank">пользовательским соглашением</a>
        и
        <a href="/p/privacy" target="_blank">политикой конфиденциальности</a>.
      </span>
      <span><br/>Соглашаюсь с условиями:</span>
      <Checkbox v-model="acceptedTerms" label="Пользовательского соглашения" id="acceptedTerms" />
      <Checkbox v-model="acceptedPrivacyPolicy" label="Политики конфиденциальности" id="acceptedPrivacyPolicy" />
      <span>Хочу получать:</span>
      <Checkbox v-model="subscribedToEmails" label="Рассылку по E-mail" id="subscribedToEmails" />
      <div class="button register" @click="registerClicked">Зарегистрироваться</div>
    </div>
    <div class="overlay" v-if="isShowingLoadingBlock">
      <LoadingBlock v-if="isShowingLoadingBlock" />
    </div>
  </main>
</template>

<script>
import Checkbox from '@/components/Shared/Checkbox'
import LoadingBlock from '@/components/Shared/LoadingBlock'
export default {
  name: 'Register',
  components: { LoadingBlock, Checkbox },

  data() {
    return {
      email: '',
      password: '',
      repeatPassword: '',
      username: '',
      acceptedTerms: false,
      acceptedPrivacyPolicy: false,
      subscribedToEmails: false,
      isShowingLoadingBlock: false,
    }
  },

  methods: {
    registerClicked(event) {
      if(this.password !== this.repeatPassword) {
        alert('Пароли должны совпадать. Пожалуйста, попробуйте ввести их заново')
        this.password = ''
        this.repeatPassword = ''
        return
      }

      this.isShowingLoadingBlock = true
      this.$root.makeAPIRequest('auth', 'register', {
        email: this.email.trim(),
        password: this.password.trim(),
        username: this.username.trim(),
        acceptedTerms: this.acceptedTerms,
        acceptedPrivacyPolicy: this.acceptedPrivacyPolicy,
        subscribedToEmails: this.subscribedToEmails,
      })
        .then(response => {
          if(response.data.error) {
            console.error(response.data.message)
            alert(response.data.message)
            this.isShowingLoadingBlock = false
            return
          }

          alert('Регистрация завершена успешно. На Вашу электронную почту было выслано письмо, содержащее ссылку для активации профиля. Пожалуйста, перейдите по ней, чтобы Вы смогли войти в профиль.')
          this.$router.replace({ name: 'home' })
        })
    },

    showLogInOverlay(event) {
      event.preventDefault()
      this.$root.overlay = 'log-in'
    },
  }
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  main {
    width: var(--content-width);
    align-self: center;
    position: relative;
  }

  .form {
    .small-form();
  }

  .big {
    font-family: @font-set-header;
    font-size: @font-size-header;
    display: block;
    text-align: center;
    margin-bottom: 0.5em;
  }

  input {
    .input();
    width: 100%;
  }

  .button {
    .big-button();
    align-self: center;
    background-color: @color-accent;
    margin-top: 0.5em;
  }

  .overlay {
    .overlay();
    background-color: fadeout(@color-page-background, 20%);
  }
</style>
