<template>
  <main>
    <LoadingBlock/>
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
export default {
  name: 'ActivateProfile',
  components: { LoadingBlock },
  props: ['key'],

  mounted() {
    this.$root.makeAPIRequest('auth', 'activate', {
      key: this.key,
    })
      .then(response => {
        if(response.data.error) {
          console.error(response.data.message)
          alert('Произошла ошибка активации. Возможно, профиль уже активирован, либо ссылка уже недействительна, либо вы пытаетесь активировать профили слишком часто.')
          this.$router.replace({ name: 'home' })
          return
        }

        alert('Активация профиля проша успешно! Теперь Вы можете войти.')
        this.$router.replace({ name: 'home' })
      })
  },
}
</script>

<style lang="less" scoped>

</style>
